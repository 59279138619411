/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-plug-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4H6zM7 1v1h1V1zm2 0v1h1V1zM5.5 5a.5.5 0 00-.5.5v4.894a2 2 0 00.336 1.11l.83 1.245c.544.816.834 1.774.834 2.754 0 .275.222.497.497.497h2.006a.497.497 0 00.497-.497c0-.98.29-1.938.834-2.754l.83-1.245a2 2 0 00.336-1.11V5.5a.5.5 0 00-.5-.5z"/>',
    },
});
